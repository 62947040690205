import React from 'react';
import Marquee from "react-fast-marquee";

export const MarqueeText = ({ children, time }) => {
  return (
    <div className="marquee">
      <Marquee speed={100} pauseOnHover={true} style={{ color: "blue" }}>
        {children}
      </Marquee>
    </div>
  );
};