import { Center, Container, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState, CSSProperties } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import CustomButton1 from "../../components/CustomButton1";
import SelectImage from "../../components/SelectImage";
import { errorMess, infoMess } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { addUserPolicy, provider_action } from "../../utils/apis";
import Select from "react-select";
import { useSelector } from "react-redux";
import store from "../../redux/Store";
import {
  getOther_Policy_list,
  getPolicy_provider_list,
} from "../../redux/slice/policySlice";
import { BiArrowBack } from "react-icons/bi";
import { MoonLoader } from "react-spinners";
import SelectBox from "../../components/SelectBox";

const Addpolicy = () => {
  const { other_policy_list } = useSelector((state) => state.policy);
  const navigate = useNavigate();
  const [p_data, setP_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [provider_list, setProvider_list] = useState([]);
  const [u_loading, setU_loading] = useState(false);
  const [pdf, setPdf] = useState("");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const data = useLocation((state) => state.state);
  const dataType = data.state;
  useEffect(() => {
    const fetchData = () => {
      if (dataType && dataType != "") {
        provider_by_type(dataType.value);
        setP_data({ ...p_data, type: dataType, company: null, img: {} });
      }
    };
    fetchData();
  }, [dataType]);

  const add_policy = () => {
    setLoading(true);
    const char = p_data?.name?.trim();
    const firstChar = char?.charAt(0);

    if (
      !p_data?.name?.trim() ||
      !p_data?.company ||
      !p_data?.plan?.trim() ||
      !p_data?.p_no?.trim() ||
      !p_data?.date ||
      !p_data?.img
    ) {
      infoMess("Please fill all fields");
      setLoading(false);
      return;
    }
    if (/^[^a-zA-Z]+$/.test(firstChar)) {
      infoMess(
        "Name could not contain special characters or spaces at the beginning"
      );
      setLoading(false);
      return;
    }

    const body = new FormData();
    body.append("action", "create");
    body.append("userid", ID);
    body.append(
      "instype",
      p_data?.type.value == "Other" ? p_data?.other.value : p_data?.type.value
    );
    body.append("holname", p_data?.name?.trim());
    body.append("polcompany", p_data?.company.value?.trim());
    body.append("polplan", p_data?.plan?.trim());
    body.append("polnum", p_data?.p_no?.trim());
    body.append("expiry", p_data?.date);
    body.append("policyfile", p_data?.img);
    addUserPolicy(body, setLoading).then(
      (v) => v?.success && navigate("/", { replace: true })
    );
  };

  const policies_list = [];
  for (let i = 0; i < other_policy_list.length; i++) {
    const element = {
      value: other_policy_list[i].policyname,
      label: other_policy_list[i].policyname,
    };
    policies_list.push(element);
  }
  const options = [
    { value: "Motor", label: "Motor insurance" },
    { value: "Health", label: "Health insurance" },
    { value: "Life", label: "Life insurance" },
    { value: "Others", label: "Others" },
  ];

  const upload_doc = async (file) => {
    try {
      setU_loading(true);
      var body = new FormData();
      body.append("document", file);
      body.append(
        "instype",
        p_data.type.value == "Other" ? p_data.other.value : p_data.type.value
      );
      body.append("polcompany", p_data.company.value);
      const response = await fetch("https://upload.insugo.in/upload", {
      // const response = await fetch("http://localhost:3000/upload", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      setU_loading(false);
      if (res.success) {
        setP_data({
          ...p_data,
          date: res.data.expiry,
          p_no: res.data.polnum,
          name: res.data.username,
          img: file,
          plan: res.data.plan,
        });
      } else {
        errorMess(res.message);
      }
    } catch (error) {
      setU_loading(false);
    }
  };
  useEffect(() => {
    store.dispatch(getPolicy_provider_list());
    store.dispatch(getOther_Policy_list());
  }, []);

  const provider_by_type = (type) => {
    provider_action(type, setLoading1).then((v) => {
      if (v.success) {
        const pro_list = [];
        for (let i = 0; i < v.message.length; i++) {
          const element = {
            value: v.message[i].provider,
            label: v.message[i].provider,
          };
          pro_list.push(element);
        }
        setProvider_list(pro_list);
      }
    });
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_policy();
      }
    },
    [p_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     color: state.isSelected ? 'white' : 'black', // color of the text
  //     backgroundColor: state.isSelected ? '#4797ed' : 'white', // background color
  //     fontWeight:state.isSelected?'bold':"400",
  //   }),
  // };
  return (
    <>
      <Container maxW={"container.xl"} w={"full"} mt={5}>
        <HStack display={"flex"} justifyContent={"space-between"}>
          <BreadCrumName
            current={"Add Policy"}
            titlelist={[
              { name: "Home", to: "/" },
              ,
              { name: "Policy", to: -1 },
            ]}
          />
          <CustomButton1
            icon={<BiArrowBack />}
            title={"Back"}
            onClick={() => navigate(-1)}
          />
        </HStack>
        <Container
          w={"full"}
          maxW={"md"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack
            flexDirection={"column"}
            maxW={"md"}
            alignSelf={"center"}
            color={"#fff"}
          >
            <Text mb={0}>Select policy type*</Text>
            <SelectBox
              value={p_data.type}
              placeholder={"Search and select policy"}
              onChange={(v) => {
                setP_data({ ...p_data, type: v, company: null, img: {} });
                provider_by_type(v.value);
              }}
              options={options}
            />
            <Stack pt={"4"}>
              {p_data?.type?.value == "Others" && (
                <>
                  <Text mb={0}>Select other policy*</Text>
                  <SelectBox
                    value={p_data.other}
                    placeholder={"Search and select other policy"}
                    onChange={(v) =>
                      setP_data({ ...p_data, other: v, img: "" })
                    }
                    options={policies_list}
                  />
                </>
              )}
              {p_data?.type?.value && (
                <>
                  <Text mb={0}>Policy company*</Text>
                  <SelectBox
                    placeholder={"Search and select policy company"}
                    value={p_data.company}
                    onChange={(v) =>
                      setP_data({ ...p_data, company: v, img: "" })
                    }
                    options={provider_list}
                  />
                </>
              )}
              {p_data?.company?.value && (
                <>
                  <SelectImage
                    doctype={".pdf,.png,.jpeg,.jpg"}
                    label={"Upload policy copy*"}
                    mt={"10px"}
                    filename={p_data?.img?.name}
                    type={p_data?.img?.type}
                    url={p_data?.img && pdf}
                    onChange={(e) => {
                      upload_doc(e.target.files[0]);
                      setPdf(window.URL.createObjectURL(e.target.files[0]));
                      setP_data({
                        ...p_data,
                        img: e.target.files[0],
                        date: "",
                        p_no: "",
                        name: "",
                        plan: "",
                      });
                    }}
                  />

                  <CustomInput
                    placeholder={"Enter Policy holder name"}
                    label={"Policy holder name*"}
                    value={p_data.name}
                    // onChange={(v) =>
                    //   setP_data({ ...p_data, name: v.target.value })
                    // }

                    onChange={(v) => {
                      // const inputValue = v.target.value;
                      // const firstChar = inputValue.charAt(0);

                      // if (/^[a-zA-Z]+$/.test(firstChar) || inputValue.length === 0) {
                      setP_data({ ...p_data, name: v.target.value });
                      // }
                    }}
                  />

                  <CustomInput
                    placeholder={
                      p_data.type?.value == "Motor"
                        ? "Enter Vehicle number"
                        : "Enter Policy Plan"
                    }
                    label={
                      p_data.type?.value == "Motor"
                        ? "Vehicle number*"
                        : "Policy Plan*"
                    }
                    value={p_data.plan}
                    onChange={(v) =>
                      setP_data({ ...p_data, plan: v.target.value })
                    }
                  />
                  <CustomInput
                    placeholder={"Enter Policy number"}
                    label={"Policy number*"}
                    value={p_data.p_no}
                    onChange={(v) =>
                      setP_data({ ...p_data, p_no: v.target.value })
                    }
                  />

                  <CustomInput
                    placeholder={"Enter Expiry Date"}
                    label={"Expiry Date*"}
                    type={"date"}
                    value={p_data.date}
                    min={"1900-01-01"}
                    max="9999-12-31"
                    onChange={(v) =>
                      setP_data({ ...p_data, date: v.target.value })
                    }
                  />
                  <Center>
                    <CustomButton1
                      title={"Submit"}
                      onClick={add_policy}
                      loading={loading}
                    />
                  </Center>
                </>
              )}
            </Stack>
          </Stack>
        </Container>
      </Container>
      {u_loading && (
        <div
          style={{
            height: "90%",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.2)",
            position: "absolute",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <MoonLoader
            color={"#000"}
            loading={true}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Addpolicy;
