import {
  Center,
  Container,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  delete_acc,
  profile_action,
  reset_doc_pass,
  reset_otp,
} from "../../utils/apis";
import {
  emailRegex,
  encryptData,
  errorMess,
  passwordRegex,
  successMess,
} from "../../utils/utils";
import MobileNumber from "./MobileNumber";
import store from "../../redux/Store";
import { getUserData } from "../../redux/slice/userSlice";
import CustomPopup from "../../components/CustomPopup";
import { useNavigate } from "react-router-dom";
import DocPass from "./DocPass";
import { IoClose } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";

const Profile = () => {
  const navigate = useNavigate();
  const { user_data } = useSelector((state) => state.user);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const [u_data, setU_data] = useState({});
  const [edit, setEdit] = useState(false);
  const [c_pass, setC_pass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [doc_reset, setDoc_reset] = useState(false);

  const [show, setShow] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    show_otp: "",
    reset_otp: "",
    show_reset_otp: false,
    reset_id: "",
    reset_otp_val: false,
  });
  const [pass, setPass] = useState({
    current: "",
    current_mess: "",
    current_validate: false,
    new: "",
    new_mess: "",
    new_validate: false,
    c_new: "",
    c_new_mess: "",
    c_new_validate: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [doc_pass, setDoc_pass] = useState(false);
  const [doc, setDoc] = useState({});
  const [disBtn, setDisBtn] = useState(false);
  const [Name, setName] = useState("");
  const [editName, setEditName] = useState(false);

  useEffect(() => {
    if (show?.show_reset_otp) {
      const timeoutId = setTimeout(() => {
        setDisBtn(false);
      }, 60000);
    }
  }, [show?.show_reset_otp]);

  useEffect(() => {
    localStorage.getItem("user");
    store.dispatch(getUserData(user.userid));
    setU_data(user_data);
  }, []);

  const change_pass = () => {
    if (
      !pass.current?.trim() ||
      !pass.new?.trim() ||
      !pass.c_new?.trim() ||
      !passwordRegex.test(pass.new?.trim()) ||
      pass.new?.trim() !== pass.c_new?.trim()
    ) {
      setPass({
        ...pass,
        current_mess: !pass.current ? "Current password is required" : "",
        current_validate: !pass.current ? true : false,
        new_mess: !pass.new
          ? "New password is required"
          : !passwordRegex.test(pass.new)
          ? "Password must be at least 8 characters long and contain at least  one special character !"
          : "",
        new_validate: !pass.new || !passwordRegex.test(pass.new) ? true : false,
        c_new_mess: !pass.c_new
          ? "Confirm password is required"
          : pass.new !== pass.c_new
          ? "Confirm Password does not match New Password"
          : "",
        c_new_validate: !pass.c_new || pass.new !== pass.c_new ? true : false,
      });
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("password", encryptData(pass.current?.trim())); //encrypted password
    body.append("newpassword", encryptData(pass.new?.trim())); //encrypted password
    body.append("action", "changepwd");
    profile_action(body, setLoading, "pass").then((v) => {
      if (v.success) {
        successMess(v.message);
        setC_pass(false);
        setLoading("");
        setPass({
          ...pass,
          current: "",
          current_mess: "",
          current_validate: false,
          new: "",
          new_mess: "",
          new_validate: false,
          c_new: "",
          c_new_mess: "",
          c_new_validate: false,
        });
      } else {
        errorMess(v.message);
        setLoading("");
      }
    });
  };
  const change_doc_pass = () => {
    if (
      !doc.current?.trim() ||
      !doc.new?.trim() ||
      !doc.c_new?.trim() ||
      doc.new?.trim() !== doc.c_new?.trim()
    ) {
      setDoc({
        ...doc,
        current_mess: !doc.current ? "Current password is required" : "",
        current_validate: !doc.current ? true : false,
        new_mess: !doc.new ? "New password is required" : "",
        new_validate: !doc.new ? true : false,
        c_new_mess: !doc.c_new
          ? "Confirm password is required"
          : doc.new !== doc.c_new
          ? "Confirm Password does not match New Password"
          : "",
        c_new_validate: !doc.c_new || doc.new !== doc.c_new ? true : false,
      });
      return;
    }

    const formdata = new FormData();
    formdata.append("action", "changedocpwd");
    formdata.append("userid", ID);
    formdata.append("password", encryptData(doc.current?.trim())); //encrypted password
    formdata.append("newpassword", encryptData(doc.new?.trim())); //encrypted password
    profile_action(formdata, setLoading, "doc").then((v) => {
      if (v.success) {
        successMess(v.message);
        setDoc_pass(false);
        setLoading("");
        setDoc({ ...doc });
      } else {
        errorMess(v.message);
        setLoading("");
      }
    });
  };
  const update_profile = () => {
    if (!u_data.Name?.trim() && !u_data.Email?.trim()) {
      setU_data({
        ...u_data,
        Name_mess: "Name is required",
        Name_validate: true,
        Email_mess: "Email is required",
        Email_validate: true,
      });
      return;
    }
    if (!u_data.Name?.trim()) {
      setU_data({
        ...u_data,
        Name_mess: "Name is required",
        Name_validate: true,
      });
      return;
    }
    if (!u_data.Email?.trim()) {
      setU_data({
        ...u_data,
        Email_mess: "Email is required",
        Email_validate: true,
      });
      return;
    }
    if (!emailRegex.test(u_data.Email?.trim())) {
      setU_data({
        ...u_data,
        Email_mess: "Invalid email address",
        Email_validate: true,
      });
      return;
    }

    const body = new FormData();
    body.append("userid", ID);
    body.append("name", u_data.Name?.trim());
    body.append("email", u_data.Email?.trim());
    body.append("action", "update");
    profile_action(body, setLoading, "pro").then((v) => {
      store.dispatch(getUserData(user.userid));
      if (v.success) {
        successMess(v.message);
        setEdit(false);
      } else {
        errorMess(v.message);
      }
    });
  };

  const get_otp = (no) => {
    reset_otp(no, no, setLoading).then((v) => {
      if (v.success) {
        setShow({ ...show, show_reset_otp: true, reset_id: v.id });
        successMess(v.message);
        setDisBtn(true);
      } else {
        errorMess(v.message);
        setShow({ ...show, show_reset_otp: false });
      }
    });
  };

  const onResetDocPass = () => {
    if (!doc.reset_doc_pass || !show.reset_otp) {
      !doc.reset_doc_pass &&
        setDoc({
          ...doc,
          reset_doc_pass_mess: "Password is required",
          reset_doc_pass_validate: true,
        });
      !show.reset_otp &&
        setShow({
          ...show,
          reset_otp_val: true,
        });
      return;
    }
    const body = new FormData();
    body.append("id", show.reset_id?.trim());
    body.append("action", "resetdocpassword");
    body.append("otp", show.reset_otp?.trim());
    body.append("contact", user_data.mobile?.trim());
    body.append("password", encryptData(doc.reset_doc_pass?.trim()));
    reset_doc_pass(body, setLoading2).then((v) => {
      if (v.success) {
        store.dispatch(getUserData(user.userid));
        successMess(v.message);
        setDoc_reset(false);
        setShow({ ...show, show_reset_otp: false, reset_otp: "" });
        setDoc({ ...doc, reset_doc_pass: "" });
      } else {
        errorMess(v.message);
      }
    });
  };

  const update_name = async () => {
    if (u_data?.Name?.length > 3) {
      try {
        const body = new FormData();
        body.append("userid", ID);
        body.append("action", "update");
        body.append("name", u_data?.Name);
        profile_action(body, setLoading, "pro").then((v) => {
          store.dispatch(getUserData(user.userid));
          if (v.success) {
            successMess(v.message);
            setEditName(false);
          } else {
            errorMess(v.message);
          }
        });
      } catch (error) {
      }
    } else {
      setU_data({
        ...u_data,
        Name_mess: "Please Enter Valid Name",
        Name_validate: false,
      });
    }
  };
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Profile"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container
        maxW={"container.md"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Text
            mb={0}
            fontSize={["md", "lg", "xl", "2xl"]}
            fontWeight={"medium"}
            color={"#fff"}
          >
            Profile Details
          </Text>
        </Flex>
      </Container>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CustomInput
          textonly={!editName}
          label={"Name"}
          value={editName ? u_data.Name : user_data?.Name}
          isInvalid={u_data?.Name_validate}
          error={u_data?.Name_mess}
          onChange={(e) => {
            setU_data({
              ...u_data,
              Name: e.target.value,
              Name_mess: "",
              Name_validate: false,
            });
          }}
          rightButton2={
            <>
              <Center mt={1} display={"flex"} gap={".5rem"}>
                {!editName ? (
                  <CustomButton1
                    title={"Edit"}
                    onClick={() => setEditName(!editName)}
                    icon={<BiEditAlt size={20} />}
                  />
                ) : (
                  <>
                    <CustomButton1
                      title={<div className="update_btn">Update</div>}
                      onClick={update_name}
                      loading={loading == "pro" ? true : false}
                      icon={
                        <div className="update_icon">
                          <FaArrowRight />
                        </div>
                      }
                    />
                    <CustomButton1
                      title={<div className="cancel_btn">Cancel</div>}
                      onClick={() => setEditName(!editName)}
                      icon={<IoClose size={20} />}
                      bg={"#727b82"}
                    />
                  </>
                )}
              </Center>
            </>
          }
        />
        <CustomInput
          textonly={!edit}
          label={"Email"}
          value={edit ? u_data.Email : user_data?.Email}
          isInvalid={u_data?.Email_validate}
          error={u_data?.Email_mess}
          onChange={(e) => {
            setU_data({
              ...u_data,
              Email: e.target.value,
              Email_mess: "",
              Email_validate: false,
            });
          }}
        />
        {edit && (
          <Center mt={3}>
            <CustomButton1
              title={"Update"}
              onClick={update_profile}
              loading={loading == "pro" ? true : false}
            />
          </Center>
        )}
      </Container>
      <MobileNumber />
      {user.type == "Primary" && (
        <Container
          maxW={"container.md"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={5}
        >
          <CustomButton1
            title={c_pass ? "Cancel" : "Change Password"}
            onClick={() => {
              setDoc_pass(false);
              setDoc_reset(false);
              setC_pass(!c_pass);
            }}
            bg={c_pass ? "#727b82" : "#e0a604"}
          />
        </Container>
      )}
      {c_pass && (
        <Container
          w={"full"}
          maxW={"md"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CustomInput
            placeholder="Password"
            type={show.one ? "text" : "password"}
            value={pass.current}
            label={"Current Password*"}
            onChange={(e) =>
              setPass({
                ...pass,
                current: e.target.value,
                current_mess: "",
                current_validate: false,
              })
            }
            isInvalid={pass.current_validate}
            error={pass.current_mess}
            right={
              !show.one ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, one: !show.one })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, one: !show.one })}
                />
              )
            }
          />
          <CustomInput
            placeholder="Password"
            type={show.two ? "text" : "password"}
            value={pass.new}
            label={"New Password*"}
            onChange={(e) =>
              setPass({
                ...pass,
                new: e.target.value,
                new_mess: "",
                new_validate: false,
              })
            }
            isInvalid={pass.new_validate}
            error={pass.new_mess}
            right={
              !show.two ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, two: !show.two })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, two: !show.two })}
                />
              )
            }
          />
          <CustomInput
            placeholder="Password"
            type={show.three ? "text" : "password"}
            value={pass.c_new}
            label={"Confirm New Password*"}
            onChange={(e) =>
              setPass({
                ...pass,
                c_new: e.target.value,
                c_new_mess: "",
                c_new_validate: false,
              })
            }
            isInvalid={pass.c_new_validate}
            error={pass.c_new_mess}
            right={
              !show.three ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, three: !show.three })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, three: !show.three })}
                />
              )
            }
          />

          <Center mt={3}>
            <CustomButton1
              title={"Update"}
              onClick={change_pass}
              loading={loading == "pass" ? true : false}
            />
          </Center>
        </Container>
      )}
      <Container
        maxW={"container.md"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={5}
      >
        <DocPass />
      </Container>
      {user_data.docpwd_status == 1 && (
        <Container
          maxW={"container.md"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={5}
          flexDirection={["column", "column", "row", "row"]}
          gap={2}
        >
          <CustomButton1
            title={doc_pass ? "Cancel" : "Change Document Password"}
            onClick={() => {
              setC_pass(false);
              setDoc_reset(false);
              setDoc_pass(!doc_pass);
            }}
            bg={doc_pass ? "#727b82" : "#e0a604"}
          />
          <CustomButton1
            title={doc_reset ? "Cancel" : "Reset Document Password"}
            onClick={() => {
              setC_pass(false);
              setDoc_pass(false);
              setDoc_reset(!doc_reset);
            }}
            bg={doc_reset ? "#727b82" : "#e0a604"}
          />
        </Container>
      )}
      {doc_pass && (
        <Container
          w={"full"}
          maxW={"md"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CustomInput
            placeholder="Password"
            type={show.four ? "text" : "password"}
            value={doc.current}
            label={"Current Password*"}
            onChange={(e) =>
              setDoc({
                ...doc,
                current: e.target.value,
                current_mess: "",
                current_validate: false,
              })
            }
            isInvalid={doc.current_validate}
            error={doc.current_mess}
            right={
              !show.four ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, four: !show.four })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, four: !show.four })}
                />
              )
            }
          />
          <CustomInput
            placeholder="Password"
            type={show.five ? "text" : "password"}
            value={doc.new}
            label={"New Password*"}
            onChange={(e) =>
              setDoc({
                ...doc,
                new: e.target.value,
                new_mess: "",
                new_validate: false,
              })
            }
            isInvalid={doc.new_validate}
            error={doc.new_mess}
            right={
              !show.five ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, five: !show.five })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, five: !show.five })}
                />
              )
            }
          />
          <CustomInput
            placeholder="Password"
            type={show.six ? "text" : "password"}
            value={doc.c_new}
            label={"Confirm New Password*"}
            onChange={(e) =>
              setDoc({
                ...doc,
                c_new: e.target.value,
                c_new_mess: "",
                c_new_validate: false,
              })
            }
            isInvalid={doc.c_new_validate}
            error={doc.c_new_mess}
            right={
              !show.six ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, six: !show.six })}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShow({ ...show, six: !show.six })}
                />
              )
            }
          />

          <Center mt={3}>
            <CustomButton1
              title={"Update"}
              onClick={change_doc_pass}
              loading={loading == "doc" ? true : false}
            />
          </Center>
        </Container>
      )}
      {doc_reset && (
        <Container
          w={"full"}
          maxW={"md"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CustomInput
            textonly
            label={"Mobile"}
            placeholder={"Enter Mobile Number"}
            value={user_data?.mobile}
            // onChange={(e) => {
            //   e.target.value.length < 11 &&
            //     setU_data({
            //       ...u_data,
            //       reset_mobile: e.target.value.replace(/\D/g, ""),
            //     });
            // }}
            rightButton={
              <CustomButton1
                title={show?.show_reset_otp ? "Resend OTP" : "Get OTP"}
                mt={1}
                onClick={() => get_otp(user_data?.mobile)}
                loading={loading == 2 ? true : false}
                disabled={disBtn}
              />
            }
          />
          {show.show_reset_otp && (
            <>
              <HStack
                justify={"center"}
                mt={3}
                borderColor={show.reset_otp_val && "red"}
              >
                <PinInput
                  placeholder="-"
                  autoFocus
                  value={show.reset_otp}
                  onChange={(v) =>
                    setShow({ ...show, reset_otp: v, reset_otp_val: false })
                  }
                >
                  <PinInputField color={"#fff"} />
                  <PinInputField color={"#fff"} />
                  <PinInputField color={"#fff"} />
                  <PinInputField color={"#fff"} />
                  <PinInputField color={"#fff"} />
                  <PinInputField color={"#fff"} />
                </PinInput>
              </HStack>

              <CustomInput
                placeholder="Password"
                type={show.seven ? "text" : "password"}
                value={doc.reset_doc_pass}
                label={"Password*"}
                onChange={(e) =>
                  setDoc({
                    ...doc,
                    reset_doc_pass: e.target.value,
                    reset_doc_pass_mess: "",
                    reset_doc_pass_validate: false,
                  })
                }
                isInvalid={doc.reset_doc_pass_validate}
                error={doc.reset_doc_pass_mess}
                right={
                  !show.seven ? (
                    <FiEye
                      cursor={"pointer"}
                      size={18}
                      onClick={() => setShow({ ...show, seven: !show.seven })}
                    />
                  ) : (
                    <FiEyeOff
                      cursor={"pointer"}
                      size={18}
                      onClick={() => setShow({ ...show, seven: !show.seven })}
                    />
                  )
                }
              />
              <Center mt={3}>
                <CustomButton1
                  title={"Update"}
                  onClick={onResetDocPass}
                  loading={loading2}
                />
              </Center>
            </>
          )}
        </Container>
      )}

      {user.type == "Primary" && (
        <Container
          maxW={"container.md"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={5}
        >
          <CustomButton1
            title={"Delete Account"}
            onClick={onOpen}
            loading={loading1}
          />
        </Container>
      )}
      <CustomPopup
        isOpen={isOpen}
        onClick={() => {
          delete_acc(ID, setLoading1, navigate).then((v) => {
            onClose();
          });
        }}
        onClose={onClose}
        title={"Delete account"}
        mess={"Are you sure you want to delete this account?"}
        // b_name={"Delete"}
      />
    </Container>
  );
};

export default Profile;
